import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from '../api';
import TasksList from './TasksList';
import TaskForm from './TaskForm';

const TaskManager = ({
  tasks,
  setTasks,
  setCurrentTask
}) => {
    const [taskId, setTaskId] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [taskType, setTaskType] = useState('');
    const [requestData, setRequestData] = useState('');
    const [responseData, setResponseData] = useState('');
    const [showCreateTaskForm, setShowCreateTaskForm] = useState(false);
    const [editingTask, setEditingTask] = useState(null);

    const taskTypes = [
        "create image",
        "doc search",
        "execute python",
        "process document",
        "process image",
        "process text",
        "shop goodwill",
        "web search"
      ];

    const handleCreateTask = async () => {
      try {
        const newTaskId = uuidv4();
        await api.tasks.createTask({
          id: newTaskId,
          description: taskDescription,
          taskType,
          requestData,
          responseData,
        });
        setTasks([...tasks, { 
          id: newTaskId, 
          description: taskDescription, 
          taskType, 
          requestData, 
          responseData 
        }]);
        setShowCreateTaskForm(false);
      } catch (err) {
        console.error(err);
      }
    };    

  const handleUpdateTask = async () => {
    try {
      await api.tasks.updateTask(taskId, {
        description: taskDescription,
        taskType,
        requestData,
        responseData,
      });
      setTasks(tasks.map((task) => task.id === taskId ? { 
        id: taskId, 
        description: taskDescription, 
        taskType, 
        requestData, 
        responseData 
      } : task));
      setShowCreateTaskForm(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateTaskResponse = async () => {
    try {
      await api.tasks.updateTaskResponse(taskId, {
        responseData,
      });
      setTasks(tasks.map((task) => task.id === taskId ? { 
        id: taskId, 
        description: task.description, 
        taskType: task.taskType, 
        requestData: task.requestData, 
        responseData 
      } : task));
      setShowCreateTaskForm(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowCreateTaskForm = () => {
    setShowCreateTaskForm(true);
    setTaskId('');
    setTaskDescription('');
    setTaskType('');
    setRequestData('');
    setResponseData('');
    setEditingTask(null);
  };

  const handleCancelTaskForm = () => {
    setShowCreateTaskForm(false);
    setTaskId('');
    setTaskDescription('');
    setTaskType('');
    setRequestData('');
    setResponseData('');
    setEditingTask(null);
  };

  const handleStartEditingTask = (task) => {
    setEditingTask(task.id);
    setTaskId(task.id);
    setTaskDescription(task.description);
    setTaskType(task.taskType);
    setRequestData(task.requestData);
    setResponseData(task.responseData);
    setShowCreateTaskForm(true);
  };

  const handleSelectTask = (task) => {
    try {
      const requestDataJson = JSON.parse(task.requestData);
      const responseDataJson = task.responseData && task.responseData.length > 1 ? JSON.parse(task.responseData) : {};
      const currentTask = {
        id: task.id,
        type: task.taskType,
        requestData: requestDataJson,
        responseData: responseDataJson,
      };
      setCurrentTask(currentTask);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await api.tasks.deleteTask(taskId);
      setTasks(tasks.filter((t) => t.id !== taskId));
    } catch (err) {
      console.error(err);
    }
  };

  const onChangeRequestData = (newRequestData) => {
    if (typeof newRequestData === 'object') {
      setRequestData(JSON.stringify(newRequestData));
    } else {
      setRequestData(newRequestData);
    }
  };
  
  return (
    <div>
      <button className="button" onClick={handleShowCreateTaskForm}>Create New Task</button>
      {showCreateTaskForm && (
        <TaskForm
          title={editingTask ? 'Edit Task' : 'Create New Task'}
          taskTypes={taskTypes}
          taskId={taskId}
          taskDescription={taskDescription}
          taskType={taskType}
          requestData={requestData}
          responseData={responseData}
          confirm={editingTask ? handleUpdateTask : handleCreateTask}
          cancel={handleCancelTaskForm}
          isCreating={!editingTask}
          onChangeTaskDescription={(description) => setTaskDescription(description)}
          onChangeTaskType={(type) => setTaskType(type)}
          onChangeRequestData={(data) => onChangeRequestData(data)}
          onChangeResponseData={(data) => setResponseData(data)}
        />
      )}
      <TasksList
        tasks={tasks}
        onSelect={handleSelectTask}
        onDeleteTask={handleDeleteTask}
        onEditTask={handleStartEditingTask}
        />
    </div>
  );
};

export default TaskManager;
