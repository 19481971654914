import React, { useState } from 'react';
import api from '../api';
import ProfileForm from './ProfileForm';

const ProfileManager = ({
  profiles,
  setProfiles
}) => {
  const [profileId, setProfileId] = useState('');
  const [profileName, setProfileName] = useState('');
  const [profileDescription, setProfileDescription] = useState('');
  const [showCreateProfileForm, setShowCreateProfileForm] = useState(false);
  const [editingProfile, setEditingProfile] = useState(null);

  const handleCreateProfile = async () => {
    try {
      await api.profiles.createProfile({
        id: Math.random().toString(36).substr(2, 9),
        name: profileName,
        description: profileDescription,
      });
      setProfiles([...profiles, { id: profileId, name: profileName, description: profileDescription }]);
      setShowCreateProfileForm(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      await api.profiles.updateProfile(profileId, {
        name: profileName,
        description: profileDescription,
      });
      setProfiles(profiles.map((profile) => profile.id === profileId ? { id: profileId, name: profileName, description: profileDescription } : profile));
      setShowCreateProfileForm(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleShowCreateProfileForm = () => {
    setShowCreateProfileForm(true);
    setProfileId('');
    setProfileName('');
    setProfileDescription('');
    setEditingProfile(null);
  };

  const handleCancelProfileForm = () => {
    setShowCreateProfileForm(false);
    setProfileId('');
    setProfileName('');
    setProfileDescription('');
    setEditingProfile(null);
  };

  const handleStartEditingProfile = (profile) => {
    setEditingProfile(profile.id);
    setProfileId(profile.id);
    setProfileName(profile.name);
    setProfileDescription(profile.description);
    setShowCreateProfileForm(true);
  };

  const handleDeleteProfile = async (profileId) => {
    try {
      await api.profiles.deleteProfile(profileId);
      setProfiles(profiles.filter((p) => p.id !== profileId));
    } catch (err) {
      console.error(err);
    }
  };

  const renderProfiles = () => {
    return profiles.map((profile) => (
      <div key={profile.id} style={{ marginLeft: '10px' }}>
        {profile.name}
        <button className="button-small" onClick={() => handleStartEditingProfile(profile)}>
          Edit
        </button>
        <button className="button-small-red" onClick={async () => {
          if (window.confirm(`Are you sure you want to delete profile ${profile.name}?`)) {
            handleDeleteProfile(profile.id);
          }
        }}>
          Delete
        </button>
      </div>
    ));
  };

  return (
    <div>
      <button className="button" onClick={handleShowCreateProfileForm}>Create New Profile</button>
      {showCreateProfileForm && (
        <ProfileForm
          title={editingProfile ? 'Edit Profile' : 'Create New Profile'}
          profileId={profileId}
          profileName={profileName}
          profileDescription={profileDescription}
          confirm={editingProfile ? handleUpdateProfile : handleCreateProfile}
          cancel={handleCancelProfileForm}
          isCreating={!editingProfile}
          onChangeProfileName={(name) => setProfileName(name)}
          onChangeProfileDescription={(description) => setProfileDescription(description)}
        />
      )}
      <ul>
        {renderProfiles()}
      </ul>
    </div>
  );
};

export default ProfileManager;
