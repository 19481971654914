// src/components/ProfileForm.js
import React from 'react';

const ProfileForm = ({ 
  title, 
  profileId, 
  profileName, 
  profileDescription, 
  confirm, 
  cancel, 
  isCreating, 
  onChangeProfileName, 
  onChangeProfileDescription 
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <button onClick={cancel}>Close</button>
        </div>
        <div className="modal-body">
          <input 
            className="modal-body-path"
            type="text" 
            placeholder="Profile Name" 
            value={profileName} 
            onChange={(e) => onChangeProfileName(e.target.value)} 
          />
          <textarea 
            className="modal-body-content"
            placeholder="Profile Description"
            value={profileDescription} 
            onChange={(e) => onChangeProfileDescription(e.target.value)} 
          />
        </div>
        <div className="modal-footer">
          <button className="button-medium" onClick={confirm}>
            {isCreating ? 'Create Profile' : 'Update Profile'}
          </button>
          <button className="button-medium" onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileForm;
