const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
const getHeaders = () => {
  const apiKey = localStorage.getItem('apiKey');
  return {
    'Content-Type': 'application/json',
    'x-kcok': apiKey,
  };
};

const projectApi = {
    async createFile(projectId, file_path, content) {
      try {
        const res = await fetch(`${API_URL}/gitlab/file`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({
            branch: `development`,
            commit_message: `ai created file`,
            projectId: projectId,
            file_path: file_path,
            content: content
          }),
        });
  
        const data = await res.json();
        if (data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (err) {
        throw new Error('An error creating file.');
      }
    },

    async deleteFile(projectId, file_path) {
      try {
        const res = await fetch(`${API_URL}/gitlab/file`, {
          method: 'DELETE',
          headers: getHeaders(),
          body: JSON.stringify({
            branch: `development`,
            commit_message: `ai deleted file`,
            projectId: projectId,
            file_path: file_path
          }),
        });
  
        const data = await res.json();
        if (data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (err) {
        throw new Error('An error creating file.');
      }
    },

    async getGroups() {
        try {
            const response = await fetch(`${API_URL}/gitlab/groups`, {
              method: 'GET',
              headers: getHeaders(),
            });
            const data = await response.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data.response;
        } catch (err) {
            throw new Error('An error occurred while fetching GitLab groups.');
        }
    },
    
    async getProjects(groupId) {
        try {
          const response = await fetch(`${API_URL}/gitlab/projects${groupId ? `?groupId=${groupId}` : ''}`, {
            method: 'GET',
            headers: getHeaders(),
          });
          const data = await response.json();
          if (data.error) {
            throw new Error(data.error);
          }
          return data;
        } catch (err) {
          throw new Error('An error occurred while fetching projects.');
        }
    },

    async getProject(id) {
        try {
        const res = await fetch(`${API_URL}/gitlab/project?id=${id}`, {
            method: 'GET',
            headers: getHeaders(),
        });

        const data = await res.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
        } catch (err) {
        throw new Error('An error occurred while fetching project.');
        }
    },

    async getMergeRequests(projectId) {
        try {
        const res = await fetch(`${API_URL}/gitlab/merge-requests?projectId=${projectId}`, {
            method: 'GET',
            headers: getHeaders(),
        });

        const data = await res.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
        } catch (err) {
        throw new Error('An error occurred while fetching merge requests.');
        }
    },

    async getMergeRequest(projectId, mergeRequestId) {
        try {
        const res = await fetch(`${API_URL}/gitlab/merge-request?projectId=${projectId}&mergeRequestId=${mergeRequestId}`, {
            method: 'GET',
            headers: getHeaders(),
        });

        const data = await res.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
        } catch (err) {
        throw new Error('An error occurred while fetching merge request.');
        }
    },

    async getAllOpenMergeRequests() {
        try {
          const response = await fetch(`${API_URL}/gitlab/all-open-merge-requests`, {
            method: 'GET',
            headers: getHeaders(),
          });
          const data = await response.json();
          if (data.error) {
            throw new Error(data.error);
          }
          return data;
        } catch (err) {
          throw new Error('An error occurred while fetching open merge requests.');
        }
    },

    async getRepositoryFiles(projectId, ref) {
        try {
        const res = await fetch(`${API_URL}/gitlab/repository/files?projectId=${projectId}&ref=${ref}`, {
            method: 'GET',
            headers: getHeaders(),
        });

        const data = await res.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data.response;
        } catch (err) {
        throw new Error('An error occurred while fetching repository files.');
        }
    },

    async getFile(projectId, ref, path) {
        try {
        const res = await fetch(`${API_URL}/gitlab/file?projectId=${projectId}&ref=${ref}&path=${path}`, {
            method: 'GET',
            headers: getHeaders(),
        });

        const data = await res.json();
        if (data.error) {
            throw new Error(data.error);
        }
        return data;
        } catch (err) {
        throw new Error('An error occurred while fetching file.');
        }
    },

    async downloadProjectZip(projectId) {
        try {
          const response = await fetch(`${API_URL}/gitlab/project/${projectId}/archive.zip`, {
            method: 'GET',
            headers: getHeaders(),
          });
      
          if (response.ok) {
            return response;
          } else {
            throw new Error('Failed to download zip archive');
          }
        } catch (err) {
          throw new Error('An error occurred while downloading project zip.');
        }
    },

    async updateFile(projectId, file_path, content) {
      try {
        const res = await fetch(`${API_URL}/gitlab/file`, {
          method: 'PUT',
          headers: getHeaders(),
          body: JSON.stringify({
            branch: `development`,
            commit_message: `ai updated file`,
            projectId: projectId,
            file_path: file_path,
            content: content
          }),
        });
  
        const data = await res.json();
        if (data.error) {
          throw new Error(data.error);
        }
        return data;
      } catch (err) {
        throw new Error('An error creating file.');
      }
    }
      
}
  
export default projectApi;
