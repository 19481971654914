const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
const getHeaders = () => {
  const apiKey = localStorage.getItem('apiKey');
  return {
    'Content-Type': 'application/json',
    'x-kcok': apiKey,
  };
};

const profilesApi = {
  async createProfile(profileData) {
    try {
      const res = await fetch(`${API_URL}/profile`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(profileData),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while creating profile.');
    }
  },

  async getProfile(id) {
    try {
      const res = await fetch(`${API_URL}/profile/${id}`, {
        method: 'GET',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while fetching profile.');
    }
  },

  async getProfiles() {
    try {
      const res = await fetch(`${API_URL}/profile`, {
        method: 'GET',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while fetching profiles.');
    }
  },

  async updateProfile(id, profileData) {
    try {
      const res = await fetch(`${API_URL}/profile/${id}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(profileData),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while updating profile.');
    }
  },

  async deleteProfile(id) {
    try {
      const res = await fetch(`${API_URL}/profile/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
        console.log(err)
      throw new Error('An error occurred while deleting profile.');
    }
  },
};

export default profilesApi;
