import React from 'react';

const ConversationList = ({ conversations, onSelect, onDeleteConversation }) => {
  return (
    <div className="conversation-list">
      <h2>Conversations:</h2>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <ul>
        {conversations.map((conversation) => (
          <li key={conversation.id}>
          <div>
            <button className='conversation-list-button' onClick={() => onSelect(conversation.id)}>
              {conversation.description}
            </button>
            <button
              className="button-small-red"
              onClick={async () => {
                if (window.confirm(`Are you sure you want to delete this conversation?`)) {
                  await onDeleteConversation(conversation.id);
                }
              }}
            >
              Delete
            </button>
          </div>
        </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default ConversationList;
