import { default as conversationApi } from './conversationApi'
import profilesApi from './profilesApi';
import { default as projectApi } from './projectApi'
import searchApi from './searchApi';
import tasksApi from './tasksApi';

const api = {
  conversation: conversationApi,
  profiles: profilesApi,
  project: projectApi,
  search: searchApi,
  tasks: tasksApi
};

export default api;
