const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
const getHeaders = () => {
  const apiKey = localStorage.getItem('apiKey');
  return {
    'Content-Type': 'application/json',
    'x-kcok': apiKey,
  };
};

const docApi = {
  async getDocument(id) {
    try {
      const res = await fetch(`${API_URL}/doc/${id}`, {
        method: 'GET',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data.data;
    } catch (err) {
      throw new Error('An error occurred while getting document.');
    }
  },

  async getDocuments() {
    try {
      const res = await fetch(`${API_URL}/doc`, {
        method: 'GET',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data.data;
    } catch (err) {
      throw new Error('An error occurred while getting documents.');
    }
  },

  async saveDocument(file, id) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', id)
    try {
      const res = await fetch(`${API_URL}/doc`, {
        method: 'POST',
        headers: getHeaders(),
        body: formData,
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while saving document.');
    }
  },

  async updateDocument(id, file) {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const res = await fetch(`${API_URL}/doc/${id}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: formData,
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while updating document.');
    }
  },

  async deleteDocument(id) {
    try {
      const res = await fetch(`${API_URL}/doc/${id}`, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while deleting document.');
    }
  },

  async extractTextFromPdf(id) {
    try {
      const res = await fetch(`${API_URL}/extract-text-from-pdf/${id}`, {
        method: 'GET',
        headers: getHeaders(),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data.data;
    } catch (err) {
      throw new Error('An error occurred while extracting text from pdf.');
    }
  },
};

export default docApi;
