// components/ConversationContainer.js
import React from 'react';
import Message from './Message';

const ConversationContainer = ({ conversation, onDeleteMessage, onSpeechMessage }) => {
  return (
    <div className="conversation-container">
      <h2>Conversation:</h2>
      {conversation && conversation
        .filter((item) => item.role !== 'system')
        .map((item, index) => (
          <Message
            key={index}
            item={item}
            onDeleteMessage={onDeleteMessage}
            onSpeechMessage={onSpeechMessage}
          />
        ))}
    </div>
  );
};

export default ConversationContainer;
