import React from 'react';

const TasksList = ({ tasks, onSelect, onDeleteTask, onEditTask }) => {
  return (
    <div className="tasks-list">
      <h2>Tasks:</h2>
      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
      <ul>
        {tasks.map((task) => (
          <li key={task.id}>
            <div>
            <button className='task-list-button' onClick={() => onSelect(task)}>
                Do
              </button>
              <button className='task-list-button' onClick={() => onEditTask(task)}>
                {task.description}
              </button>
              <button
                className="button-small-red"
                onClick={async () => {
                  if (window.confirm(`Are you sure you want to delete this task?`)) {
                    await onDeleteTask(task.id);
                  }
                }}
              >
                Delete
              </button>
            </div>
          </li>
        ))}
      </ul>
      </div>
    </div>
  );
};

export default TasksList;
