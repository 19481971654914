// api/authApi.js
const API_URL = process.env.REACT_APP_AUTH_CHECK_URL;

const getHeaders = (apiKey) => {
  return {
    'Content-Type': 'application/json',
    'x-api-key': apiKey,
  };
};

const authApi = {
  async login(email, password) {
    try {
      const res = await fetch(`${API_URL}/login`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ email, password }),
      });

      console.log(res)

      if (res.ok) {
        const data = await res.json();
        return data;
      } else {
        throw new Error('Email or password is incorrect');
      }
    } catch (err) {
        console.log(err)
      throw new Error('An error occurred during login.');
    }
  },

  async validateApiKey(apiKey) {
    try {
      const res = await fetch(`${API_URL}/protected`, {
        method: 'GET',
        headers: getHeaders(apiKey),
      });

      if (res.ok) {
        const data = await res.json();
        return data.userId;
      } else {
        throw new Error('API key is invalid or has expired');
      }
    } catch (err) {
      throw new Error('An error occurred while validating API key.');
    }
  },
};

export default authApi;
