const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
const getHeaders = () => {
    const apiKey = localStorage.getItem('apiKey');
    return {
      'Content-Type': 'application/json',
      'x-kcok': apiKey,
    };
};

const conversationApi = {
    async createConversation(conversationData, stream = false, onStreamData = null) {
        try {
            if (stream && onStreamData) {
                const res = await fetch(`${API_URL}/ai/conversation`, {
                    method: 'POST',
                    headers: getHeaders(),
                    body: JSON.stringify({ ...conversationData, stream: true }),
                });

                const reader = res.body.getReader();
                const decoder = new TextDecoder();

                while (true) {
                    const { value, done } = await reader.read();
                    if (done) break;

                    const chunk = decoder.decode(value, { stream: true });
                    const lines = chunk.split('\n');

                    for (const line of lines) {
                        if (line.trim().startsWith('data:')) {
                            const jsonData = line.replace('data: ', '');
                            try {
                                const parsed = JSON.parse(jsonData);
                                onStreamData(parsed);
                            } catch (err) {
                                console.error('Error parsing JSON:', err);
                            }
                        }
                    }
                }

                return null; // No final response, data handled in onStreamData
            } else {
                const res = await fetch(`${API_URL}/ai/conversation`, {
                    method: 'POST',
                    headers: getHeaders(),
                    body: JSON.stringify(conversationData),
                });

                const data = await res.json();
                if (data.error) {
                    throw new Error(data.error);
                }
                return data;
            }
        } catch (err) {
            throw new Error('An error occurred while fetching data.');
        }
    },

    async createImage(prompt) {
        try {
            const res = await fetch(`${API_URL}/ai/image`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({
                    prompt: prompt
                }),
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while fetching data.');
        }
    },

    async createSummary(input_text, max_length) {
        try {
            const res = await fetch(`${API_URL}/ai/summarize`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({
                    input_text: input_text,
                    max_length: max_length
                }),
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while fetching data.');
        }
    },

    async deleteConversation(conversationId) {
        try {
            const res = await fetch(`${API_URL}/ai/conversation`, {
              method: 'DELETE',
              headers: getHeaders(),
              body: JSON.stringify({
                  id: conversationId
              }),
              
            });
      
            const data = await res.json();
            if (data.error) {
              throw new Error(data.error);
            }
            return data;
        } catch (err) {
              console.log(err)
            throw new Error('An error occurred while deleting conversation.');
        }
    },

    async describeImage(prompt, image) {
        try {
            const res = await fetch(`${API_URL}/ai/describe`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({
                    prompt: prompt,
                    image: image
                }),
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while fetching data.');
        }
    },

    async getConversation(id) {
        try {
            const res = await fetch(`${API_URL}/ai/conversation?id=${id}`, {
                method: 'GET',
                headers: getHeaders(),
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while fetching conversation.');
        }
    },

    async getConversations() {
        try {
            const res = await fetch(`${API_URL}/ai/conversations`, {
                method: 'GET',
                headers: getHeaders(),
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while fetching conversation.');
        }
    },

    async saveConversation(conversationData) {
        try {
            const res = await fetch(`${API_URL}/ai/save`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(conversationData),
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while saving conversation.');
        }
    },

    async textToSpeech(text, lang = 'en') {
        try {
            const res = await fetch(`${API_URL}/ai/text-speech`, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify({
                    text: text,
                    lang: lang
                }),
            });

            const data = await res.blob();
            return data;
        } catch (err) {
            throw new Error('An error occurred while converting text to speech.');
        }
    },

    async speechToText(audio) {
        try {
            const formData = new FormData();
            formData.append('audio', audio);

            const res = await fetch(`${API_URL}/ai/speech-text`, {
                method: 'POST',
                headers: getHeaders(),
                body: formData,
            });

            const data = await res.json();
            if (data.error) {
                throw new Error(data.error);
            }
            return data;
        } catch (err) {
            throw new Error('An error occurred while converting speech to text.');
        }
    },
};

export default conversationApi;
