// src/components/FileForm.js
import React from 'react';

const FileForm = ({ 
  title, 
  projectId, 
  filePath, 
  fileContent, 
  confirm, 
  cancel, 
  isCreating, 
  onChangeFilePath, 
  onChangeFileContent 
}) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <button onClick={cancel}>Close</button>
        </div>
        <div className="modal-body">
          <input 
            className="modal-body-path"
            type="text" 
            placeholder="File Path" 
            value={filePath} 
            onChange={(e) => onChangeFilePath(e.target.value)} 
          />
          <textarea 
            className="modal-body-content"
            value={fileContent} 
            onChange={(e) => onChangeFileContent(e.target.value)} 
          />
        </div>
        <div className="modal-footer">
          <button className="button-medium" onClick={confirm}>
            {isCreating ? 'Create File' : 'Update File'}
          </button>
          <button className="button-medium" onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileForm;
