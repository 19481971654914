// components/ImageUploader.js
import React, { useState } from 'react';

const ImageUploader = ({ onImageLoaded }) => {
  const [image, setImage] = useState(null);
  const [base64String, setBase64String] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result
      setBase64String(base64Data);
      setImage(file);
      onImageLoaded(base64Data.split(',')[1]);
    };
    reader.readAsDataURL(file);
  };

  const handleImageClear = () => {
    setImage(null);
    setBase64String('');
    onImageLoaded(null); // Inform the parent component that the image has been cleared
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} />
      {image && (
        <div>
          <img src={base64String} alt={image.name} className="resized-image" />
          <button onClick={handleImageClear}>Clear</button>
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
