import React, { useState, useEffect, useCallback } from 'react';
import api from '../api';
import FileForm from './FileForm';

const ProjectList = ({ setMessage }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [projectData, setProjectData] = useState({});
  const [error, setError] = useState(null);
  const [expandedPaths, setExpandedPaths] = useState({});
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [newFileContent, setNewFileContent] = useState('');
  const [newFilePath, setNewFilePath] = useState('');
  const [showCreateFileForm, setShowCreateFileForm] = useState(false);
  const [editingFile, setEditingFile] = useState(null);

  useEffect(() => {
    const loadGroups = async () => {
      try {
        const response = await api.project.getGroups();
        setGroups(response);
      } catch (err) {
        setError(err.message);
      }
    };
    loadGroups();
  }, []);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const response = await api.project.getProjects(selectedGroup?.id);
        const projectsWithMergeRequestCount = await Promise.all(response.response.map(async (project) => {
          const mergeRequestResponse = await api.project.getMergeRequests(project.id);
          const openMergeRequests = mergeRequestResponse.response.filter((mergeRequest) => mergeRequest.state === 'opened');
          return { ...project, openMergeRequestCount: openMergeRequests.length };
        }));
        setProjects(projectsWithMergeRequestCount);
      } catch (err) {
        setError(err.message);
      }
    };
    if (selectedGroup) {
      loadProjects();
    }
  }, [selectedGroup]);

  const renderProjectFiles = (files, filePath = '', indentLevel = 0, isFirstLevel = true) => {
    return Object.keys(files).map((fileName) => (
      <div key={fileName} style={{ marginLeft: `${indentLevel * 10}px` }}>
        {Object.keys(files[fileName]).length > 0 && (
          <span>
            {expandedPaths[filePath + '/' + fileName] ? (
              <button onClick={() => setExpandedPaths((prevExpandedPaths) => ({ ...prevExpandedPaths, [filePath + '/' + fileName]: false }))}>
                - {fileName}
              </button>
            ) : (
              <button onClick={() => setExpandedPaths((prevExpandedPaths) => ({ ...prevExpandedPaths, [filePath + '/' + fileName]: true }))}>
                + {fileName}
              </button>
            )}
          </span>
        )}
        {fileName.endsWith('.txt') || fileName.endsWith('.sh') || fileName.endsWith('.py') || fileName.endsWith('.md') || fileName.endsWith('.swift') || fileName.endsWith('.yml') || fileName.endsWith('.js') || fileName.endsWith('.html') || fileName.endsWith('.css') || fileName.endsWith('.sql') ? (
          <span>
            {fileName}
            <button className="button-small" onClick={() => {
              let finalFilePath = (filePath + '/' + fileName).replace(/^0?\//, '');
              handleSendFileToConversation(finalFilePath, selectedProject.id);
            }}>
              Send
            </button>
            <button className="button-small" onClick={() => {
              handleStartEditingFile((filePath + '/' + fileName).replace(/^0?\//, ''));
            }}>
              Edit
            </button>
            <button className="button-small-red" onClick={() => handleDeleteFile((filePath + '/' + fileName).replace(/^0?\//, ''), selectedProject.id)}>
              Del
            </button>
          </span>
        ) : null}
        {expandedPaths[filePath + '/' + fileName] && Object.keys(files[fileName]).length > 0 && (
          <div>
            {renderProjectFiles(files[fileName], `${filePath}/${fileName}`, indentLevel + 1, false)}
          </div>
        )}
      </div>
    ));
  };  

  const handleProjectClick = async (project) => {
    setSelectedProject(project);
    try {
      if (!projectData[project.id]) {
        const mergeRequestResponse = await api.project.getMergeRequests(project.id);
        const openMergeRequests = mergeRequestResponse.response.filter((mergeRequest) => mergeRequest.state === 'opened');
        const projectFilesResponse = await api.project.getRepositoryFiles(project.id, 'development');
        setProjectData((prevProjectData) => ({ ...prevProjectData, [project.id]: { mergeRequests: openMergeRequests, projectFiles: projectFilesResponse } }));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const renderProjectFilesToString = (files) => {
    const getFilesAsString = (files, indentLevel = 0) => {
      let filesAsString = '';
      Object.keys(files).forEach((fileName) => {
        filesAsString += `${'  '.repeat(indentLevel)}${fileName}\n`;
        if (Object.keys(files[fileName]).length > 0) {
          filesAsString += getFilesAsString(files[fileName], indentLevel + 1);
        }
      });
      return filesAsString;
    };
    return getFilesAsString(files);
  };

  const handleSendFileToConversation = useCallback(
    async (fileName, projectId) => {
      try {
        const fileResponse = await api.project.getFile(projectId, 'development', fileName);
        const fileContent = fileResponse.response;
        if (fileContent) {
          setMessage(`File contents: \n\n${fileName}\n\`\`\`\n${fileContent}\n\`\`\`\n`);
        } else {
          setMessage(`Error: Unable to retrieve file contents.`);
        }
      } catch (err) {
        setError(err.message);
      }
    },
    [setMessage, setError]
  ); 

  const handleSendMessage = useCallback(() => {
    if (selectedProject) {
      const projectFileStructure = renderProjectFilesToString(projectData[selectedProject.id].projectFiles);
      setMessage(`Here is the project file structure:\n\n${selectedProject.name}\n\`\`\`\n${projectFileStructure}\n\`\`\`\n`);
    }
  }, [setMessage, selectedProject, projectData]);

  const handleDownloadZip = useCallback(async () => {
    try {
      const response = await api.project.downloadProjectZip(selectedProject.id);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${selectedProject.name}.zip`;
      a.click();
      URL.revokeObjectURL(url);
    } catch (err) {
      setError(err.message);
    }
  }, [selectedProject, setError]);

  const handleFileAction = useCallback(async (action) => {
    try {
      const method = action === 'create' ? api.project.createFile : api.project.updateFile;
      const filePath = editingFile || newFilePath;
      const response = await method(selectedProject.id, filePath, newFileContent);
      if (action === 'create') {
        setNewFileContent('');
        setNewFilePath('');
      } else {
        setEditingFile(null);
      }
      const projectFilesResponse = await api.project.getRepositoryFiles(selectedProject.id, 'development');
      setProjectData((prevProjectData) => ({ ...prevProjectData, [selectedProject.id]: { ...prevProjectData[selectedProject.id], projectFiles: projectFilesResponse } }));
      setShowCreateFileForm(false);
    } catch (err) {
      setError(err.message);
    }
  }, [selectedProject, newFileContent, newFilePath, editingFile, setError]);

  const handleShowCreateFileForm = () => {
    setShowCreateFileForm(true);
    setNewFilePath('');
    setNewFileContent('');
    setEditingFile(null);
  };

  const handleStartEditingFile = useCallback(async (filePath) => {
    try {
      const fileResponse = await api.project.getFile(selectedProject.id, 'development', filePath);
      const fileContent = fileResponse.response;
      setEditingFile(filePath);
      setNewFilePath(filePath);
      setNewFileContent(fileContent);
      setShowCreateFileForm(true);
    } catch (err) {
      setError(err.message);
    }
  }, [selectedProject, setError]);

  const cancelForm = () => {
    setShowCreateFileForm(false);
    setNewFilePath('');
    setNewFileContent('');
    setEditingFile(null);
  };

  // Function to handle file deletion
  const handleDeleteFile = useCallback(async (filePath, projectId) => {
    if (window.confirm("Are you sure you want to delete this file?")) {
      try {
        await api.project.deleteFile(projectId, filePath);
        const projectFilesResponse = await api.project.getRepositoryFiles(projectId, 'development');
        setProjectData((prevProjectData) => ({ ...prevProjectData, [projectId]: { ...prevProjectData[projectId], projectFiles: projectFilesResponse } }));
      } catch (err) {
        setError(err.message);
      }
    }
  }, [setError, setProjectData]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="project-list-container"> 
      <select value={selectedGroup?.id} onChange={(e) => setSelectedGroup(groups.find((group) => group.id === parseInt(e.target.value)))}>
        <option value="">All Groups</option>
        {groups.length > 0 && groups.map((group) => (
          <option key={group.id} value={group.id}>{group.name}</option>
        ))}
      </select>
      <h2>Projects:</h2>
      <ul className="project-list"> 
        {projects.map((project) => (
          <li key={project.id}>
            <span onClick={() => handleProjectClick(project)} style={{ cursor: 'pointer' }}>
              {project.name} ({project.openMergeRequestCount > 0 ? project.openMergeRequestCount : ''})
            </span>
            {selectedProject && selectedProject.id === project.id && (
              <div className="project-box">
                <ul style={{ padding: 0, listStyle: 'none' }}>
                  {projectData[project.id] && projectData[project.id].mergeRequests.map((mergeRequest) => (
                    <li key={mergeRequest.id}>
                      {mergeRequest.title} (Merge Request #{mergeRequest.id})
                    </li>
                  ))}
                </ul>
                <h3 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <span>Files:</span>
                  <button className="button-medium" onClick={handleSendMessage}>Send to Conversation</button>
                  <button className="button-medium" onClick={handleDownloadZip}>Download Zip</button>
                  <button className="button-medium" onClick={handleShowCreateFileForm}>New File</button>
                </h3>
                <div>
                  {projectData[project.id] && projectData[project.id].projectFiles && renderProjectFiles(projectData[project.id].projectFiles, '', 0, true)}
                </div>
                {showCreateFileForm && (
                  <FileForm 
                    title={editingFile ? "Edit File" : "Create New File"}
                    projectId={selectedProject.id} 
                    filePath={editingFile || newFilePath} 
                    fileContent={newFileContent} 
                    confirm={() => handleFileAction(editingFile ? 'update' : 'create')} 
                    cancel={cancelForm} 
                    onChangeFilePath={(filePath) => setNewFilePath(filePath)} 
                    onChangeFileContent={(fileContent) => setNewFileContent(fileContent)} 
                  />
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProjectList;