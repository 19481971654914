// src/Auth.js
import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import authApi from './api/authApi';

const Auth = ({ children }) => {
  const token = localStorage.getItem('apiKey');
  const location = useLocation();
  const [isValid, setIsValid] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const validateApiKey = async () => {
      if (token) {
        try {
          await authApi.validateApiKey(token);
          setIsValid(true);
        } catch (error) {
          setIsValid(false);
          setError(error.message);
        }
      } else {
        setIsValid(false);
      }
    };

    validateApiKey();
  }, [token]);

  if (isValid === null) {
    return null; // or a loading indicator
  }

  if (!isValid) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (error) {
    console.error('Error validating API key:', error);
  }

  return children;
};

export default Auth;
