// components/ProcessDocument.js
import React, { useState } from 'react';
import docApi from '../api/docApi';

const ProcessDocument = ({ requestData, onChangeRequestData }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState(requestData.text || '');

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    docApi.saveDocument(file, requestData.id).then((data) => {
      if (data.error) {
        throw new Error(data.error);
      }
      return docApi.extractTextFromPdf(requestData.id);
    }).then((pdfText) => {
      setText(pdfText);
      onChangeRequestData({ ...requestData, text: pdfText });
    }).catch((err) => console.error(err));
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    onChangeRequestData({ ...requestData, text: event.target.value, prompt: `Process Document` });
  };

  return (
    <div>
      <label>Engine:</label>
      <input 
        type="text" 
        value={requestData.engine} 
        onChange={(e) => onChangeRequestData({ ...requestData, engine: e.target.value })} 
        placeholder="Engine (default: chromadb)"
      />
      <label>ID:</label>
      <input 
        type="text" 
        value={requestData.id} 
        onChange={(e) => onChangeRequestData({ ...requestData, id: e.target.value })} 
      />
      <label>Text:</label>
      <textarea 
        className="modal-body-content"
        value={text} 
        onChange={handleTextChange} 
      />
      <input type="file" onChange={handleFileChange} accept=".txt,.pdf" />
    </div>
  );
};

export default ProcessDocument;
