// components/Message.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Message = ({ item, onDeleteMessage, onSpeechMessage  }) => {
  const renderMessage = (content) => (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          return !inline ? (
            <div className="code-block">
              <SyntaxHighlighter
                style={vscDarkPlus}
                language={match ? match[1] : ''}
                PreTag="div"
                {...props}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
              <button
                className="copy-button"
                onClick={() => navigator.clipboard.writeText(String(children))}
              >
                Copy
              </button>
            </div>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const handleDeleteMessage = () => {
    onDeleteMessage(item);
  };

  const handleSpeechMessage = () => {
    onSpeechMessage(item);
  };

  if (item.role === 'image') {
    const dataURI = `data:image/jpeg;base64,${item.content}`;
    return (
      <div className="conversation-item">
        <img src={dataURI} alt="Generated Image" />
        <button
          className="button-small-red"
          onClick={handleDeleteMessage}
        >
          Delete
        </button>
      </div>
    );
  }

  return (
    <div className="conversation-item">
      {item.role === 'user' ? (
        <div className="user-message">You: {renderMessage(item.content)}
          <button
            className="button-small-red"
            onClick={handleDeleteMessage}
          >
            Delete
          </button>
        </div>
      ) : (
        <div className="assistant-message">AI: {renderMessage(item.content)}
          <button
            className="button-small-red"
            onClick={handleDeleteMessage}
          >
            Delete
          </button>
          <button
            className="button-small"
            onClick={handleSpeechMessage}
          >
            Speech
          </button>
        </div>
      )}
    </div>
  );
};

export default Message;
