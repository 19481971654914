const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';
const getHeaders = () => {
  const apiKey = localStorage.getItem('apiKey');
  return {
    'Content-Type': 'application/json',
    'x-kcok': apiKey,
  };
};

const searchApi = {
  async search(query, engine = 'chromadb') {
    try {
      const res = await fetch(`${API_URL}/search`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ engine, query }),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data.response;
    } catch (err) {
      throw new Error('An error occurred while searching.');
    }
  },

  async processDocument(doc_id, doc_text, engine = 'chromadb') {
    try {
      const body = JSON.stringify({ engine: engine, document: {id: doc_id, text: doc_text} })
      const res = await fetch(`${API_URL}/process-document`, {
        method: 'POST',
        headers: getHeaders(),
        body: body,
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while processing document.');
    }
  },

  async processDocuments(documents, engine = 'chromadb') {
    try {
      const res = await fetch(`${API_URL}/process-documents`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({ engine, documents }),
      });

      const data = await res.json();
      if (data.error) {
        throw new Error(data.error);
      }
      return data;
    } catch (err) {
      throw new Error('An error occurred while processing documents.');
    }
  },
};

export default searchApi;
