// src/AppRoutes.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './LandingPage';
import App from './App';
import Auth from './Auth';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<LandingPage />} />
        <Route
          path="/home"
          element={
            <Auth>
              <App />
            </Auth>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
