// src/LandingPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authApi from './api/authApi';
import './App.css';

const LandingPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      if (email && password) {
        const data = await authApi.login(email, password);
        localStorage.setItem('apiKey', data.apiKey);
        localStorage.setItem('userId', data.user.id);
        localStorage.setItem('userName', data.user.name);
        localStorage.setItem('userEmail', data.user.email);
        navigate('/home');
      } else {
        setLoginError('Please enter both email and password');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setLoginError(error.message);
    }
  };

  return (
    <div className="landing-container">
      <div className="landing-logo">AutoDevOps</div>
      <div className="landing-tagline">
        AI automation and augmentation for your development pipelines
      </div>
      <form className="landing-form" onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="landing-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="landing-input"
        />
        <button type="submit" className="landing-button">
          Login
        </button>
        {loginError && (
          <div className="landing-error" style={{ color: 'red' }}>
            {loginError}
          </div>
        )}
      </form>
      <div className="landing-footer"> 2025 AutoDevOps.net</div>
    </div>
  );
};

export default LandingPage;
