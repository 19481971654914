import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from '../api';

const ConversationHandler = ({
  conversation,
  setConversation,
  conversationId,
  setConversationId,
  message,
  setMessage,
  image,
  setImage,
  isSubmitting,
  setIsSubmitting,
  isStreaming,
  setIsStreaming,
  handleDeleteMessage,
  setCreateImage,
  createImage,
  document,
  setDocument,
  docSearch,
  setDocSearch,
  webSearch,
  setWebSearch,
  shopGoodwill,
  setShopGoodwill,
  currentTask,
  setCurrentTask,
  keywords,
  setKeywords
}) => {
  const [error, setError] = useState(null);
  const [lastSavedMessage, setLastSavedMessage] = useState(null);
  const [saveTimeout, setSaveTimeout] = useState(null);

  useEffect(() => {
    const saveConversationAsync = async () => {
      try {
        const updatedConversation = conversation;
        if (updatedConversation 
            && updatedConversation.length > 1 
            && lastSavedMessage !== updatedConversation[updatedConversation.length - 1]?.content) {
          const conversationText = updatedConversation.map((message) => message.content).join(' ');
          await api.conversation.saveConversation({
            id: conversationId,
            description: conversation.description,
            messages: updatedConversation,
          });
          setLastSavedMessage(updatedConversation[updatedConversation.length - 1]?.content);
        }
      } catch (err) {
        // ...
      }
    };

    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    setSaveTimeout(setTimeout(saveConversationAsync, 30000));

    return () => {
      // Cleanup function to save the conversation when the component is unmounted
      clearTimeout(saveTimeout);
      if (lastSavedMessage !== conversation[conversation.length - 1]?.content) {
        saveConversationAsync();
      }
    };
  }, [conversation]);

  const truncateConversation = (conversation) => {
    if (conversation.length > 13) {
      return [...conversation.slice(0, 3), ...conversation.slice(conversation.length - 10)];
    }
    return conversation;
  };

  const handleStreamData = (data) => {
    // Append to the last message or create a new assistant message
    setConversation((prevConversation) => {
      const lastMessage = prevConversation[prevConversation.length - 1];
  
      if (lastMessage && lastMessage.role === 'assistant') {
        // Append data to the last assistant message
        let newData = '';
        if (data && data.choices && data.choices[0] && data.choices[0].delta && data.choices[0].delta.content) {
          newData = data.choices[0].delta.content;
        } else if (typeof data === 'string') {
          newData = data;
        }
        const updatedMessage = {
          ...lastMessage,
          content: lastMessage.content + newData,
        };
        return [...prevConversation.slice(0, -1), updatedMessage];
      } else {
        // Create a new assistant message if none exists
        let newData = '';
        if (data && data.choices && data.choices[0] && data.choices[0].delta && data.choices[0].delta.content) {
          newData = data.choices[0].delta.content;
        } else if (typeof data === 'string') {
          newData = data;
        }
        return [...prevConversation, { role: 'assistant', content: newData }];
      }
    });
  };  

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setIsStreaming(false);
    setError(null);

    try {
      const userMessage = { role: 'user', content: image ? `<|image|>${message}` : message };

      setConversation((prevConversation) => truncateConversation([...prevConversation, userMessage]));

      const updatedConversation = [...conversation, userMessage];

      const filteredConversation = updatedConversation.filter(
        (message) => message.role !== 'image' && message.role !== 'system'
      );
    
      const conversationText = filteredConversation.map((message) => message.content).join(' ');
      const summary = await api.conversation.createSummary(conversationText, 10);
      await api.conversation.saveConversation({
        id: conversationId,
        description: summary.summary,
        messages: updatedConversation,
      });

      if (document && document.id && document.text) {
        await api.search.processDocument(document.id, document.text);
      } else if (docSearch) {
        const docSearchResult = await api.search.search(userMessage.content)
        setConversation((updatedConversation) => [
          ...updatedConversation,
          { role: `system`, content: `Use these search results first before your own knowledge to answer the user's above question: \n\n  ${docSearchResult}  ` },
        ]);
        setIsStreaming(true);
        const filteredConversation = conversation.filter(
          (message) => message.role !== 'image'
        );
        
        await api.conversation.createConversation(
          {
            id: conversationId,
            messages: filteredConversation,
            stream: true,
          },
          true,
          handleStreamData
        );
        setIsStreaming(false);
    } else if (shopGoodwill) {
      const shopGoodwillResult = await api.search.search(keywords, `shopgoodwill`)
      setIsStreaming(true);
      const shopConversation = updatedConversation.filter(
        (message) => message.role !== 'image'
      );
      shopConversation.push({ role: `user`, content: `\`\`\`\n  ${shopGoodwillResult} \n\`\`\` ` })
      setConversation(shopConversation)
      await api.conversation.createConversation(
        {
          id: conversationId,
          messages: shopConversation,
          stream: true,
        },
        true,
        handleStreamData
      );
      setIsStreaming(false);
    } else if (webSearch) {
        const webSearchResult = await api.search.search(userMessage.content, `google`)
        setConversation((prevConversation) => [
          ...prevConversation,
          { role: `system`, content: `Use these search results first before your own knowledge to answer the user's above question: \n\`\`\`\n  ${webSearchResult} \n\`\`\`\n  ` },
        ]);
        setIsStreaming(true);
        const filteredConversation = updatedConversation.filter(
          (message) => message.role !== 'image'
        );
        await api.conversation.createConversation(
          {
            id: conversationId,
            messages: filteredConversation,
            stream: true,
          },
          true,
          handleStreamData
        );
        setIsStreaming(false);
      } else if (image) {
        const imageResult = await api.conversation.describeImage(
          userMessage.content,
          image
        );
        handleStreamData(imageResult.description);
      } else if (createImage) {
        const createImageResult = await api.conversation.createImage(
          userMessage.content
        )
        setConversation((prevConversation) => [
          ...prevConversation,
          { role: 'image', content: createImageResult.image },
        ]);
      } else {
        setIsStreaming(true);
        const filteredConversation = updatedConversation.filter(
          (message) => message.role !== 'image'
        );
        await api.conversation.createConversation(
          {
            id: conversationId,
            messages: filteredConversation,
            stream: true,
          },
          true,
          handleStreamData
        );
        setIsStreaming(false);
      }

      setMessage('');
      
    } catch (err) {
      setError(err.message);
    } finally {
      setCurrentTask({})
      setCreateImage(false)
      setDocSearch(false)
      setDocument(null)
      setShopGoodwill(false)
      setWebSearch(false)
      setIsSubmitting(false);
      setIsStreaming(false);
    }
  };

  const handleNewConversation = async () => {
    setConversationId(uuidv4());
    try {
      localStorage.setItem('conversationId', conversationId);
      const response = await api.conversation.getConversations();
      // setConversations(response.data);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleImageLoaded = (base64String) => {
    setImage(base64String);
  };

  return {
    handleSubmit,
    handleStreamData,
    handleNewConversation,
    handleImageLoaded,
  };
};

export default ConversationHandler;
