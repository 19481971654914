import React from 'react';
import ProcessDocument from './ProcessDocument';

const CreateImageRequestForm = ({ requestData, onChangeRequestData }) => {
  return (
    <div>
      <label>Prompt:</label>
      <textarea
        type="text" 
        className="modal-body-content"
        value={requestData.prompt} 
        onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })} 
      />
    </div>
  );
};

const DocSearchRequestForm = ({ requestData, onChangeRequestData }) => {
  return (
    <div>
      <label>Query:</label>
      <textarea
        type="text"
        className="modal-body-content"
        value={requestData.prompt}
        onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })}
      />
    </div>
  );
};

const ExecutePythonRequestForm = ({ requestData, onChangeRequestData }) => {
  return (
      <div>
        <label>Python Code:</label>
        <textarea
          type="text" 
          className="modal-body-content"
          value={requestData.prompt} 
          onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })} 
        />
      </div>
    );
};

const ProcessImageRequestForm = ({ requestData, onChangeRequestData }) => {
  return (
    <div>
        <label>Prompt:</label>
        <input 
        type="text" 
        value={requestData.prompt} 
        onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })} 
        />
        <label>Image</label>
        <img src={requestData.image} alt="missing" className="resized-image" />
    </div>
  );
};

const ProcessTextRequestForm = ({ requestData, onChangeRequestData }) => {
    return (
        <div>
          <label>Prompt:</label>
          <textarea
            type="text" 
            className="modal-body-content"
            value={requestData.prompt} 
            onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })} 
          />
        </div>
      );
};

const ShopGoodwillRequestForm = ({ requestData, onChangeRequestData }) => {
  return (
    <div>
      <label>Keywords:</label>
        <input 
        type="text" 
        value={requestData.keywords} 
        onChange={(e) => onChangeRequestData({ ...requestData, keywords: e.target.value })} 
        />
      <label>Prompt:</label>
      <textarea
        type="text"
        className="modal-body-content"
        value={requestData.prompt && requestData.prompt.length > 1 ? requestData.prompt : `Organize the following search results into the format: \nTitle \nCurrent bid \nTime Remaining \nPhotos (image URLs or actual images) \nLink to the item page https://www.shopgoodwill.com/item/{itemId} `}
        onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })}
      />
    </div>
  );
};

const WebSearchRequestForm = ({ requestData, onChangeRequestData }) => {
  return (
    <div>
      <label>Prompt:</label>
      <textarea
        type="text"
        className="modal-body-content"
        value={requestData.prompt}
        onChange={(e) => onChangeRequestData({ ...requestData, prompt: e.target.value })}
      />
    </div>
  );
};

const TaskForm = ({ 
  title, 
  taskTypes,
  taskId, 
  taskDescription, 
  taskType, 
  requestData, 
  responseData, 
  confirm, 
  cancel, 
  isCreating, 
  onChangeTaskDescription, 
  onChangeTaskType, 
  onChangeRequestData, 
  onChangeResponseData 
}) => {
  const renderRequestForm = () => {
    let parsedRequestData;
    try {
      parsedRequestData = JSON.parse(requestData);
    } catch (error) {
      parsedRequestData = {};
    }

    switch (taskType) {
      case 'create image':
        return <CreateImageRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'doc search':
        return <DocSearchRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'execute python':
        return <ExecutePythonRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'process document':
        return <ProcessDocument requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'process image':
        return <ProcessImageRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'process text':
        return <ProcessTextRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'shop goodwill':
        return <ShopGoodwillRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      case 'web search':
        return <WebSearchRequestForm requestData={parsedRequestData} onChangeRequestData={onChangeRequestData} />;
      default:
        return <p>Please select a task type</p>;
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <button onClick={cancel}>Close</button>
        </div>
        <div className="modal-body">
          <input 
            className="modal-body-path"
            type="text" 
            placeholder="Task Description" 
            value={taskDescription} 
            onChange={(e) => onChangeTaskDescription(e.target.value)} 
          />
          <select 
            className="modal-body-path"
            value={taskType} 
            onChange={(e) => onChangeTaskType(e.target.value)} 
          >
            <option value="">Select Task Type</option>
            {taskTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </select>
          {renderRequestForm()}
          <textarea 
            className="modal-body-content"
            placeholder="Response Data"
            value={responseData} 
            onChange={(e) => onChangeResponseData(e.target.value)} 
          />
        </div>
        <div className="modal-footer">
          <button className="button-medium" onClick={confirm}>
            {isCreating ? 'Create Task' : 'Update Task'}
          </button>
          <button className="button-medium" onClick={cancel}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default TaskForm;
